<template>
  <div class="container">
    <div class="OrderSettle">
      <div class="OrderSettle-title"><h3>订单结算</h3></div>

      <div class="settle">
        <div class="settle-left">
          <div class="top-title">送货方式</div>
          <div class="settle-card">

            <div class="Delivery-card-item">
              <div class="Delivery-card-item-top">
                <UserOutlined class="top-icon" />
                <span class="top-label">收货人：</span>
                <span class="top-value">
                  {{ SelectRadioAddress.length ? SelectRadioAddress[0].name : '请选择' }},
                  {{ SelectRadioAddress.length ? SelectRadioAddress[0].phone : '请选择' }}
                </span>
              </div>
            </div>
            <div class="Delivery-card-item">
              <div class="Delivery-card-item-top">
                <EnvironmentOutlined class="top-icon" />
                <span class="top-label" style="flex: 1;">收货地址：</span>
                <span class="top-other" @click="chooseAddress = true">选择收货地址</span>
              </div>
              <div class="Delivery-card-item-bottom">
                {{ SelectRadioAddress.length ? SelectRadioAddress[0].province : '请选择' }},
                {{ SelectRadioAddress.length ? SelectRadioAddress[0].city : '请选择' }},
                {{ SelectRadioAddress.length ? SelectRadioAddress[0].region : '请选择' }},
                {{ SelectRadioAddress.length ? SelectRadioAddress[0].detailAddress : '请选择' }}
              </div>
            </div>
          </div>

          <div class="top-title">订单明细</div>
          <!-- <div class="top-title-tips">本次订单将分两个包裹发出</div> -->
          <div class="settle-card">
            <div class="order-detailed">
              <div v-for="(item, index) in goodsList" :key="index" class="order-detaile-item">
                <div class="order-detaile-item-index">{{ index + 1 }}</div>
                <div class="order-detaile-item-img"><img v-lazy="item.image" :alt="item.title"></div>
                <div class="order-detaile-item-info">
                  <span class="font-bold">{{ item.title }}</span>
                  <span>{{ item.skuAttr[0] }}</span>
                  <span>¥ {{ item.price }} * {{ item.count }}</span>
                  <span class="font-bold">¥ {{ item.totalPrice }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="settle-right">
          <div class="top-title">商品结算</div>

          <div class="settlement-content">
            <div class="settlement-item">
              <span class="settlement-item-name">商品小计</span>
              <span class="settlement-item-value">¥ {{ orderInfo.total }}</span>
            </div>
            <!-- <div class="settlement-item">
              <span class="settlement-item-name">选择优惠卷</span>
              <span class="settlement-item-value"><RightOutlined /></span>
            </div> -->
            <!-- <div class="settlement-item">
              <span class="settlement-item-name">促销优惠</span>
              <span class="settlement-item-value">-¥ 0.00</span>
            </div> -->
            <!-- <div class="settlement-item" style="color: #3B6652;font-weight: bold;">
              <span class="settlement-item-name">会员优惠</span>
              <span class="settlement-item-value">-¥ 0.00</span>
            </div> -->
            <div class="settlement-item" style="color: #3B6652;font-weight: bold;">
              <span class="settlement-item-name">运费</span>
              <span class="settlement-item-value">+¥ {{ orderInfo.temPrice }}</span>
            </div>
          </div>

          <div class="total">
            <div class="total-price">
              <span>商品合计</span>
              <span class="all-price">¥ {{ orderInfo.payPrice }}</span>
            </div>
            <!-- <div class="total-Tips">
              <ExclamationCircleOutlined style="margin-right: 4px;" />
              <span>结算价格不含运输费</span>
            </div> -->
          </div>
          <button class="total-btn" @click="toPay">提交订单</button>
        </div>
      </div>
    </div>
    <!-- 选择收货地址弹窗 -->
    <a-modal v-model:visible="chooseAddress" width="800px" :footer="null" @ok="handleOk">
      <div class="choose-address">
        <h4 class="choose-address-title">选择收货地址</h4>
        <a-radio-group v-if="addressList.length" v-model:value="addressItem">
          <template v-for="(item) in addressList" :key="item.id">
            <a-radio
              :style="radioStyle"
              :value="item.id"
            >
              <span class="db-mr8">{{ item.province }}{{ item.city }}{{ item.detailAddress }}</span>
              <span class="db-mr8">{{ item.name }}</span>
              <span>{{ item.phone }}</span>
            </a-radio>
          </template>
        </a-radio-group>
        <div v-if="!addressList.length" style="height: 200px;padding: 24px 0;">
          <a-empty :description="'请前往个人中心添加收获地址'" />
        </div>

        <div class="btn-fun">
          <button v-if="addressList.length" @click="chooseAddressFun('confirm')">确认</button>
          <button v-if="!addressList.length" @click="toAddAddress">前往</button>
          <button @click="chooseAddressFun('cancle')">取消</button>
        </div>
      </div>

    </a-modal>
  </div>
</template>

<script>
import { UserOutlined, EnvironmentOutlined } from '@ant-design/icons-vue'
import { toTrade, submitOrder } from '@/api/order'
// import { listAll } from '@/api/user'
import { mapState } from 'vuex'
export default {
  name: 'OrderSettle',
  components: {
    UserOutlined,
    EnvironmentOutlined
    // RightOutlined,
    // ExclamationCircleOutlined
  },
  data() {
    return {
      chooseAddress: false,
      addressItem: '',
      radioStyle: {
        display: 'block',
        height: '30px'
      },
      goodsList: [],
      // addressList: [],
      SelectRadioAddress: [],
      orderInfo: {}
    }
  },
  computed: {
    ...mapState({
      addressList: state => state.user.address,
      User_ID: state => state.user.id
    })
  },
  created() {
    this.$nextTick(() => {
      this.getOrderInfo()
    })
  },
  methods: {
    chooseAddressFun(type) {
      if (type === 'cancle') {
        this.chooseAddress = false
        this.addressItem = ''
        this.SelectRadioAddress = []
      } else {
        if (!this.addressItem) {
          this.$message.warning('请选择收获地址')
          return
        }
        this.SelectRadioAddress = this.addressList.filter(item => item.id === this.addressItem)
        this.chooseAddress = false
      }
    },
    handleOk(e) {
      this.chooseAddress = false
    },
    pay() {

    },
    toPay() {
      // this.$router.push({ path: '/order/orderPay', query: {
      //   order_id: 23
      // }})
      if (!this.SelectRadioAddress.length) {
        this.$message.warning('请选择收获地址')
        return
      }
      const data = {
        addrId: this.SelectRadioAddress[0].id,
        // payType: 1,
        orderToken: this.orderInfo.orderToken,
        payPrice: this.orderInfo.payPrice
        // note: ''
      }
      submitOrder(data).then(res => {
        if (res.code === 0) {
          this.$message.success('订单提交成功', 1.5, _ => {
            // this.$router.push({ name: 'OrderPay', params: {
            //   order: JSON.stringify(res.submitOrderResp.order),
            //   orderItem: JSON.stringify(res.submitOrderResp.orderItems)
            // }})
            // this.$router.push({ path: '/order/orderPay', query: {
            //   order: JSON.stringify(res.submitOrderResp.order),
            //   orderItem: JSON.stringify(res.submitOrderResp.orderItems)
            // }})
            this.$router.push({
              path: '/order/orderPay',
              query: {
                orderId: res.submitOrderResp.order.id
              }
            })
          })
        }
      }).catch(rej => {
        console.log(rej)
      })
    },
    getOrderInfo() {
      toTrade().then(res => {
        this.goodsList = res.data.items
        this.orderInfo = res.data
        this.SelectRadioAddress = this.addressList.filter(item => item.defaultStatus === 1)
      })
      // if (this.User_ID) {
      // listAll(this.User_ID).then(res => {
      //   this.addressList = res.data
      //   this.SelectRadioAddress = res.data.filter(item => item.defaultStatus === 1)
      // })
    },
    toAddAddress() {
      sessionStorage.setItem('TOADDRESS', '/user/userInfo')
      this.$router.push({ path: '/user/userInfo', query: { comtype: 'Account' }})
    }
  }
}
</script>
<style lang='less' scoped>
.OrderSettle {
  padding: 64px 0;

  .OrderSettle-title {
    margin-bottom: 64px;

    & > h3 {
      margin: 0;
      color: #333333;
      font-weight: bold;
      font-size: 36px;
    }
  }
  .top-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 32px;
  }
  .top-title-tips {
    font-size: 16px;
    color: #666666;
    font-weight: 400;
    margin-bottom: 32px;
  }

  .settle {
    display: flex;
  }
  .settle-left {
    width: 868px;
    margin-right: 64px;

    .settle-card {
      width: 868px;
      min-height: 208px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
      margin-bottom: 64px;
      padding: 72px 0;

    }
    .Delivery-card-item {
      margin-bottom: 32px;
      padding: 0 72px;

      .Delivery-card-item-top {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .top-icon {
          font-size: 24px;
          font-weight: bold;
          margin-right: 8px;
        }
        .top-label {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
        }
        .top-value {
          font-size: 16px;
          color: #666666;
          font-weight: 400;
        }
        .top-other {
          color: #3B6652;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .Delivery-card-item-bottom {
        font-size: 16px;
          color: #666666;
          font-weight: 400;
          margin-left: 32px;
      }
    }

    .order-detailed {
      padding: 0 72px;
      border-bottom: 1px solid #F3F3F3;

      .order-detaile-item {
        display: flex;
        align-items: center;
        padding: 52px 0;

        .order-detaile-item-index {
          color: #333333;
          font-size: 16px;
          font-weight: Bold;
        }
        .order-detaile-item-img {
          width: 128px;
          height: 128px;
          margin-left: 8px;
          margin-right: 32px;

          & > img {
            width: 100%;
            height: 100%;
          }
        }
        .order-detaile-item-info {
          height: 128px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          & > span {
            color: #666666;
            font-weight: 400;
          }
          .font-bold {
              font-size: 16px;
              color: #333333;
              font-weight: bold;
            }
        }
      }
    }
  }

  .settle-right {

    .settlement-content {
      border-bottom: 1px solid #F3F3F3;

      .settlement-item {
        margin-bottom: 48px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
      }
    }

    .total {
      padding: 48px 0;

      .total-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333333;
        font-weight: 400;
        margin-bottom: 16px;

        .all-price {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .total-Tips {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .total-btn {
      width: 356px;
      height: 64px;
      background-color: #3B6652;
      color: #fff;
      text-align: center;
      line-height: 48px;
      border-radius: 33px;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
    }
  }

}
.choose-address {
  padding: 48px 64px;
  .choose-address-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #333333;
    margin-bottom: 24px;
  }
  .btn-fun {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;

    & > button {
      width: 173px;
      height: 46px;
      border-radius: 32px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
    }
    & > button:first-child {
      color: #fff;
      background: #3B6652;
    }
    & > button:last-child {
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      color: #999999;
    }
  }
}
</style>
